import { Button } from '@chakra-ui/react';
import { MdFilterList } from "react-icons/md";
const FilterButton = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            colorScheme="red"
            borderRadius="md"
            boxShadow="md"
            _hover={{ bg: 'red.300' }}
            _active={{ bg: 'red.300' }}
        >
            <MdFilterList style={{marginRight:'5px'}}/>
            Szűrő
        </Button>
    );
};

export default FilterButton;