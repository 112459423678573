import { FormControl, FormLabel, Flex, Button, Icon, Input, Stack, Textarea, Text, Box, VStack, HStack, useColorModeValue as mode } from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import emailjs from 'emailjs-com';
import { useState } from "react";

const Contact = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();


        const name = e.target.name.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;
        const address = e.target.address.value;
        const message = e.target.message.value;

        if (!name || !email || !phone || !address || !message) {
            setError('Kérjük, töltse ki az összes mezőt!');
            return;
        }

        setSubmitting(true);

        const serviceID = 'service_ew9u6jk';
        const templateID = 'template_8ittt7h';
        const userID = 'i0azR18OoPweD9NWR';

        try {
            const res = await emailjs.sendForm(serviceID, templateID, e.target, userID);
            console.log('Email sent:', res);
            setSubmitted(true);
            setError('');
        } catch (error) {
            console.error('Email sending error:', error);
            setError('Hiba történt az üzenet elküldése közben. Kérjük, próbálja újra később.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <VStack alignItems="center" minHeight='80vh' mx={{ base: '12', md: '20', lg: '32' }}>
                <Box display='flex' justifyContent='center' marginY='20px'>
                    <Text fontSize='4xl' fontWeight='bold'>Kapcsolat</Text>
                </Box>

                <Flex justify={{ base: 'center', md: 'center' }} flexDirection={{ base: 'column', md: 'row' }} alignItems='center' flexWrap={{ base: 'nowrap', md: 'wrap' }} width='90%' marginBottom='20px'>
                    <Stack spacing='4' w="300px" h='150px' display='flex' align='center' justify='center' bg={mode('green.300', 'gray.900')} rounded='10px' p='20px' position='relative'>
                        <Icon as={MdLocationOn} fontSize='50px' h='10' color={mode('black', 'yellow.200')} position='absolute' top='-15%' />
                        <Text fontWeight='bolder'>Cím</Text>
                        <Text>4080 Hajdúnánás Sarló utca 45.</Text>
                    </Stack>
                    <Stack my={{ base: '25px', md: '25px' }} mx={{ base: '25px', md: '25px' }} spacing='4' w="300px" h='150px' align='center' justify='center' bg={mode('green.300', 'gray.900')} rounded='10px' p='20px' position='relative'>
                        <Icon as={MdOutlineMail} fontSize='50px' h='10' color={mode('black', 'yellow.200')} position='absolute' top='-15%' />
                        <Text fontWeight='bolder'>Email cím</Text>
                        <Text><a href="mailto:t0csa91@gmail.com">t0csa91@gmail.com</a></Text>
                    </Stack>
                    <Stack spacing='4' w="300px" h='150px' align='center' justify='center' bg={mode('green.300', 'gray.900')} rounded='10px' p='20px' position='relative'>
                        <Icon as={MdLocalPhone} fontSize='35px' h='10' color={mode('black', 'yellow.200')} position='absolute' top='-15%' />
                        <Text fontWeight='bolder'>Telefonszám</Text>
                        <Text><a href="tel:+36702074102">+36 70 207 4102</a></Text>
                    </Stack>
                </Flex>

                <Box display='flex' justifyContent='center' marginY='20px'>
                    <Text fontSize='4xl' textAlign='center' fontWeight='bold'>Küldjön üzenetet</Text>

                </Box>

                <Box display='flex' textAlign='center' flexDirection='column' justifyContent='center' >
                    <Text fontSize='l' fontWeight=''>Ha bármilyen egyedi igénye lenne, vagy más időpontban szeretné átvenni rendelését, ne habozzon felvenni velünk a kapcsolatot!
                     Rendelését előre is leadhatja, és mi gondoskodunk róla, hogy a megfelelő időpontban átvehesse.</Text>
                    <Text fontSize='l' marginTop='15px' marginBottom='15px' fontWeight=''> Emellett, ha kíváncsi más termékekre is, amelyek jelenleg nincsenek kínálatunkban, vagy ha speciális rendezvényekre nagyobb mennyiségű terméket szeretne rendelni, szívesen állunk rendelkezésére.
                </Text>
                   

                </Box>


                <Box width='100%' display='flex' justifyContent='center'>
                    <Box width='100%' maxWidth='590px'>
                        <HStack spacing={{ base: '0', md: '8' }} display='flex' flexWrap='wrap'>
                            <FormControl flex='1' minW='250px'>
                                <FormLabel htmlFor='name'>Név</FormLabel>
                                <Input id='name' name='from_name' placeholder='Név' />
                            </FormControl>
                            <FormControl flex='1' minW='250px'>
                                <FormLabel htmlFor='phone'>Telefonszám</FormLabel>
                                <Input id='phone' name='from_phone' placeholder='Telefonszám' />
                            </FormControl>
                        </HStack>

                        <HStack spacing={{ base: '0', md: '8' }} display='flex' flexWrap='wrap'>
                            <FormControl flex='1' minW='250px'>
                                <FormLabel htmlFor='email'>E-mail cím</FormLabel>
                                <Input id='email' name='from_email' placeholder='E-mail cím' />
                            </FormControl>
                            <FormControl flex='1' minW='250px'>
                                <FormLabel htmlFor='address'>Lakcím</FormLabel>
                                <Input id='address' name='from_address' placeholder='Utca, házszám' />
                            </FormControl>
                        </HStack>
                    </Box>
                </Box>

                {error && (
                    <Text color="red.500" textAlign="center" mt={4}>
                        {error}
                    </Text>
                )}
                {submitted && (
                    <Text color="green.500" textAlign="center" mt={4}>
                        Az üzenet elküldve!
                    </Text>
                )}

                <Box width='100%' display='flex' mb='20px' justifyContent='center'>
                    <Box width='100%' maxWidth='590px'>
                        <FormControl>
                            <FormLabel htmlFor='message'>Megjegyzés</FormLabel>
                            <Textarea height='300px' id='message' name='message' placeholder='Megjegyzés szállítási idővel kapcsolatban stb.' />
                        </FormControl>
                    </Box>
                </Box>
                {!submitting && !submitted && (
                    <Button type="submit" bg={mode('green.300', 'gray.900')} mb='20px'>
                        Küldés
                    </Button>
                )}
                {submitting && (
                    <Button isLoading loadingText="Küldés..." bg={mode('green.300', 'gray.900')} mb='20px' pointerEvents="none">
                        Küldés
                    </Button>
                )}
            </VStack>
        </form>
    );
}

export default Contact;
