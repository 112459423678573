import { createSlice } from '@reduxjs/toolkit';

// Számolja ki a subtotal értéket
const calculateSubtotal = (cartState) => {
  let result = 0;
  cartState.forEach((item) => (result += item.qty * item.price));
  return result;
};

export const initialState = {
  loading: false,
  error: null,
  cartItems: JSON.parse(localStorage.getItem('cartItems')) ?? [],
  shipping: JSON.parse(localStorage.getItem('shipping')) ?? Number(0),
  subtotal: localStorage.getItem('cartItems') ? calculateSubtotal(JSON.parse(localStorage.getItem('cartItems'))) : 0,
  packing: JSON.parse(localStorage.getItem('packing')) ?? false,
};

const updateLocalStorage = (cart, packing) => {
  localStorage.setItem('cartItems', JSON.stringify(cart));
  localStorage.setItem('subtotal', JSON.stringify(calculateSubtotal(cart)));
  localStorage.setItem('packing', JSON.stringify(packing)); // packing állapot mentése külön
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    cartItemAdd: (state, { payload }) => {
      const existingItem = state.cartItems.find((item) => item.id === payload.id);

      if (existingItem) {
        state.cartItems = state.cartItems.map((item) => (item.id === existingItem.id ? payload : item));
      } else {
        state.cartItems = [...state.cartItems, payload];
      }
      state.loading = false;
      state.error = null;
      updateLocalStorage(state.cartItems, state.packing);
      state.subtotal = Number(calculateSubtotal(state.cartItems));
    },
    cartItemRemoval: (state, { payload }) => {
      state.cartItems = state.cartItems.filter((item) => item.id !== payload);
    
      // Ha a kosár üres, állítsuk vissza a packing értéket alapértelmezett értékre
      if (state.cartItems.length === 0) {
        state.packing = false;
      }
    
      updateLocalStorage(state.cartItems, state.packing);
      state.subtotal = calculateSubtotal(state.cartItems);
      state.loading = false;
      state.error = null;
    },
    
    setShippingCosts: (state, { payload }) => {
      state.shipping = payload;
      localStorage.setItem('shipping', payload);
    },
    clearCart: (state) => {
      localStorage.removeItem('cartItems');
      localStorage.removeItem('shipping');
      localStorage.removeItem('subtotal');
      localStorage.removeItem('packing');
      state.cartItems = [];
      state.shipping = Number(4.99);
      state.subtotal = 0;
      state.packing = false; // Packing visszaállítása alapértelmezetten false-re
      state.loading = false;
      state.error = null;
    },
    
    // Hozzáadja a setPacking akciót
    setPacking: (state, { payload }) => {
      state.packing = payload;
      updateLocalStorage(state.cartItems, state.packing); // packing mentése
    },
    
  },
});

export const { setError, setLoading, cartItemAdd, cartItemRemoval, setShippingCosts, setPacking, clearCart } = cartSlice.actions;

export default cartSlice.reducer;

export const cartSelector = (state) => state.cart;
