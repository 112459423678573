import React, { useState } from 'react';
import {
    Button,
    Image,
    Text,
    Checkbox,
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';


const Szabadsag = ({ isOpen, onClose}) => {



    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign='center'>
                    Kedves Vásárlóink!
                </AlertDialogHeader>

                <AlertDialogBody>
                    <Text>
                    Sajnálattal értesítjük, hogy <b>2024. Augusztus 19.</b>-től  <b>2024. Augusztus 23.</b>-ig nem tudjuk a kiszállítást biztosítani. <br /> <br /> Azonban örömmel várjuk Önöket személyesen üzletünkben a Sarló utca 45. szám alatt, ahol széles termékválasztékunkat megtekinthetik és megvásárolhatják. <br/><br/> Köszönjük megértésüket és türelmüket!
                    </Text>
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button onClick={onClose}>Mégsem</Button>
                    <Button colorScheme="red" onClick={onClose} ml={3}>
                        Rendben
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
    );
};

export default Szabadsag;
