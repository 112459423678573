import React, { useState } from 'react';
import { Box, Image, Text, Badge, Flex, IconButton, Skeleton, useToast, Tooltip } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { addToFavorites, removeFromFavorites } from '../redux/actions/productActions';
import { useSelector } from 'react-redux';
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import { Link as ReactLink } from 'react-router-dom';
import { addCartItem } from '../redux/actions/cartActions';
import { useEffect } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';
import ConfirmAgesAlert from './ConfirmAgesAlert';
import AdultImage from '../Image/age18.png'
import ScrollToTopButton from './ScrollToButton';

const ProductCard = ({ product, loading }) => {
	const dispatch = useDispatch();
	const { favorites } = useSelector((state) => state.product);
	const [isShown, setIsShown] = useState(false);
	const { cartItems } = useSelector((state) => state.cart);
	const toast = useToast();
	const [cartPlusDisabled, setCartPlusDisabled] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const item = cartItems.find((cartItem) => cartItem.id === product._id);
		if (item && item.qty === product.stock) {
			setCartPlusDisabled(true);
		}
	}, [product, cartItems]);

	const addItem = (id) => {
		if (cartItems.some((cartItem) => cartItem.id === id)) {
			const item = cartItems.find((cartItem) => cartItem.id === id);
			dispatch(addCartItem(id, item.qty + 1));
		} else {
			dispatch(addCartItem(id, 1));
		}
		toast({
			description: 'A tétel hozzá lett adva.',
			status: 'success',
			isClosable: true,
		});
	};


	const handleAddToCartClick = () => {
		if (product.category === 'alkohol' || product.category === 'sor_bor') {
			setIsOpen(true);
		} else {
			addItem(product._id);
		}
	};

	const handleClickToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	  };



	return (
		<Skeleton isLoaded={!loading}>
			<Box
				_hover={{ transform: 'scale(1.02)', transitionDuration: '0.5s' }}
				borderWidth='1px'
				width='250px'
				maxW={{ base: '400px' }}
				overflow='hidden'
				p='4'
				shadow='md'>
						{product.visszavalto && (
					<Image src="/images/visszavalto.webp" w='100px' position='absolute' ml='120px' rounded='50%' colorScheme='purple'/>
						
					
				)}
				{product.productIsNew && (
					<Badge position='absolute' ml='2' colorScheme='purple'>
						AKCIÓ!
					</Badge>
				)}
				
					<Box
						as={ReactLink}
						onClick={handleClickToTop}
						to={`/termekek/${product._id}`}
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Image
							onMouseEnter={() => setIsShown(true)}
							onMouseLeave={() => setIsShown(false)}
							src={product.images[isShown && product.images.length === 2 ? 1 : 0]}
							fallbackSrc="https://via.placeholder.com/150"
							alt={product.name}
							height="200px"
							alignSelf="center"
						/>
						{product.stock < 1 ? (
							<Badge alignSelf="flex-start" colorScheme="red">Elfogyott</Badge>
						) : product.stock < 5 ? (
							<Badge alignSelf="flex-start" colorScheme="yellow">Csak {product.stock}db maradt</Badge>
						) : (
							<Badge alignSelf="flex-start" colorScheme="green">Elérhető</Badge>
						)}

						<Text
							noOfLines={1}
							fontSize="xl"
							fontWeight="semibold"
							mt="2"
							textAlign="center"
						>
							{product.brand}
						</Text>
						<Text
							style={{
								textAlign: "center",
								height: "50px",
								overflow: "hidden",
							}}
							fontSize="md"
							fontWeight=""
							mt="2"
						>
							{product.name}
						</Text>

						<Text noOfLines={1} fontSize="md" color="gray.600">
							{product.subtitle}
						</Text>
					</Box>
				
				<Flex justify='center' alignItems='center' mt='2'>
					<Text fontSize='xl' fontWeight='semibold' color='green.300'>
						{product.price} Ft
					</Text>
				</Flex>
				<Flex justify='space-between' mt='2'>
					{favorites.includes(product._id) ? (
						<IconButton
							icon={<MdOutlineFavorite size='20px' />}
							colorScheme='green'
							size='sm'
							onClick={() => dispatch(removeFromFavorites(product._id))}
						/>
					) : (
						<IconButton
							icon={<MdOutlineFavoriteBorder size='20px' />}
							colorScheme='green'
							size='sm'
							onClick={() => dispatch(addToFavorites(product._id))}
						/>
					)}
					
						<IconButton
						onClick={handleClickToTop}
							icon={<BiExpand size='20' />}
							as={ReactLink}
							to={`/termekek/${product._id}`}
							colorScheme='green'
							size='sm'
						/>
					
					<Tooltip
						isDisabled={!cartPlusDisabled}
						hasArrow
						label={
							!cartPlusDisabled
								? 'Elérte a termék maximális mennyiségét. '
								: product.stock = 0
									? 'Elfogyott'
									: ''
						}>
						<IconButton
							isDisabled={product.stock <= 0 || cartPlusDisabled}

							onClick={() => handleAddToCartClick()}
							icon={<TbShoppingCartPlus size='20' />}
							colorScheme='green'
							size='sm'
						/>
					</Tooltip>
				</Flex>
			</Box>
			<ConfirmAgesAlert AdultImage={AdultImage} product={product} addItem={addItem} isOpen={isOpen} onClose={() => setIsOpen(false)} />

		</Skeleton>
	);
};

export default ProductCard;
