import { useState } from "react";
import { categoryData } from "../categoryData";
import { Link as ReactLink } from "react-router-dom";
import { WrapItem,Badge, Box, Wrap, Image, Text, Link, Flex } from "@chakra-ui/react";

const Category2 = () => {
    return (
        <Box>
            <Box display='flex' justifyContent='center' alignItems='center' marginY='20px'>
                <Text fontSize='4xl' fontWeight='bold'>Kategóriák</Text>
            </Box>
            <Wrap spacing='20px' justify='center' align='center'>
                {categoryData.map((category) => (
                    <Box position='relative' className="category-button" key={category.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <WrapItem key={category.name} display='flex' justifyContent='center' w={{ base: '40px', md: '75px' }} h={{ base: '40px', md: '75px' }}>
                            <ReactLink to={category.link} style={{ width: '100%', height: '100%', backgroundImage: `url(${category.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></ReactLink>
                        </WrapItem>
                        <Link
                            fontSize={{ base: '10px', md: '16px' }}
                            fontWeight='semibold'
                            as={ReactLink}
                            to={category.link}
                            display='flex'
                            justifyContent='center'
                            textDecoration="none"
                            _hover={{
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px red',
                            }}
                        >
                            {category.name}
                        </Link>
                        {category.new && (
                            <>
                            <Badge 
                            textAlign='center'
                             position='absolute'
                             left={{base:'-5px',md:'-5px'}}
                             top={{base:'-5px',md:'-5px'}}
                             w={{base:'60px',md:'95px'}} fontSize={{base:'0.5em',md:'0.8em'}}  colorScheme='red'>
                            ÚJDONSÁG
                        </Badge>
                            {/* <Image 
                            position='absolute'
                            left={{base:'-25px',md:'-5px'}}
                            top={{base:'-25px',md:'-5px'}}
                            src="/images/ujdonsag.png" alt="New" width="60px" height="60px" /> */}
                       </> 
                    )}
                    </Box>
                ))}
            </Wrap>
        </Box>
    );
}

export default Category2;
