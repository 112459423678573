import { GiCoffeeBeans } from "react-icons/gi";
import { GiChipsBag } from "react-icons/gi";
import { GiWrappedSweet } from "react-icons/gi";
import { FaBottleDroplet } from "react-icons/fa6";
import { GiTomato } from "react-icons/gi";
import { TbBrandSugarizer } from "react-icons/tb";
import { GiCannedFish } from "react-icons/gi";
import { PiGrainsFill } from "react-icons/pi";
import { FaBaby } from "react-icons/fa";
import { GiWaterBottle } from "react-icons/gi";
import { PiBeerBottleFill } from "react-icons/pi";
import { GiCoolSpices } from "react-icons/gi";
import { GiNoodles } from "react-icons/gi";
import { PiDogFill } from "react-icons/pi";
import { BsHouseGearFill } from "react-icons/bs";
import { FaSprayCanSparkles } from "react-icons/fa6";
import { GiFrozenOrb } from "react-icons/gi";
import { useColorMode as mode } from "@chakra-ui/react";
import { GiMilkCarton } from "react-icons/gi";
import { RiCake3Line } from "react-icons/ri";
import { GiWashingMachine } from "react-icons/gi";
import { MdCleanHands } from "react-icons/md";
import { FaToiletPaper } from "react-icons/fa";
import { FaBath } from "react-icons/fa6";
import { TbCards } from "react-icons/tb";
import { FaGears } from "react-icons/fa6";
import { GiSlicedBread } from "react-icons/gi";
// import {Image} from '../public/images2/sugar-icon.svg'
// <?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 76.02" style="enable-background:new 0 0 122.88 76.02" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M2.95,52.44l38.3,0.22c20.17,0.05,30.74-18.52,69.86-15.57c12.22,0.92,14.13,11.16,9.36,14.41 c-3.58,2.44-6.93-0.26-10.43-1.41c-2.91-0.96-6-1.55-9.27-1.81c-11.21-0.89-19.93,1.61-29.36,4.84 c-13.43,4.6-17.5,7.32-19.22,10.41C40.88,83.78,2.8,78.93,2.95,52.44L2.95,52.44z M29.87,25.75h14.01c1.19,0,2.27,0.49,3.06,1.27 l0.01,0.01c0.78,0.79,1.27,1.87,1.27,3.05v13.74c0,1.2-0.49,2.28-1.27,3.07c-0.78,0.78-1.87,1.27-3.07,1.27H29.87 c-1.19,0-2.27-0.49-3.06-1.27l-0.01-0.01c-0.78-0.79-1.27-1.87-1.27-3.06V30.09c0-1.19,0.49-2.27,1.27-3.06l0.01-0.01 C27.61,26.24,28.69,25.75,29.87,25.75L29.87,25.75z M43.43,30.55h-13.1v12.82h13.1V30.55L43.43,30.55z M4.34,25.75h14.01 c1.19,0,2.27,0.49,3.06,1.27l0.01,0.01c0.78,0.79,1.27,1.87,1.27,3.05v13.74c0,1.2-0.49,2.28-1.27,3.07 c-0.78,0.78-1.87,1.27-3.07,1.27H4.34c-1.19,0-2.27-0.49-3.06-1.27l-0.01-0.01C0.49,46.1,0,45.02,0,43.83V30.09 c0-1.19,0.49-2.27,1.27-3.06l0.01-0.01C2.08,26.24,3.16,25.75,4.34,25.75L4.34,25.75z M17.9,30.55H4.8v12.82h13.1V30.55L17.9,30.55 z M17.38,0H31.4c1.19,0,2.27,0.49,3.06,1.27l0.01,0.01c0.78,0.79,1.27,1.87,1.27,3.05v13.74c0,1.2-0.49,2.28-1.27,3.07 c-0.78,0.78-1.87,1.27-3.07,1.27H17.38c-1.19,0-2.27-0.49-3.06-1.27l-0.01-0.01c-0.78-0.79-1.27-1.87-1.27-3.06V4.34 c0-1.2,0.49-2.28,1.27-3.07C15.1,0.49,16.19,0,17.38,0L17.38,0z M30.94,4.8h-13.1v12.82h13.1V4.8L30.94,4.8z"/></g></svg>
export const categoryData = [
  {
    "name": "Kávék, Teák, Forrócsokik",
    "image": "/images/coffee_tee.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Kávé",
        "image": "/images2/tschibo_family250g1.webp",
        "link": "/kavek_teak_forrocsokik/kave"
      },
      {
        "name": "Kávékrémpor",
        "image": "/images/completakrempor200.webp",
        "link": "/kavek_teak_forrocsokik/kavekrempor"
      },
      {
        "name": "Ice Coffee",
        "image": "/images/hellicelatte.webp",
        "link": "/kavek_teak_forrocsokik/icecoffee"
      },
      {
        "name": "Forró csoki",
        "image": "/images/lafesta_forrocsoki.webp",
        "link": "/kavek_teak_forrocsokik/hotchocolate"
      },
      {
        "name": "Tea",
        "image": "/images/pickwick_citromzoldtea.webp",
        "link": "/kavek_teak_forrocsokik/tea"
      },
      {
        "name": "Kakaópor",
        "image": "/images/nestle_nesquick150g.webp",
        "link": "/kavek_teak_forrocsokik/kakao"
      },
    ],
    "link": "/kavek_teak_forrocsokik",
    "svg": <GiCoffeeBeans style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Cukrok, Édesítők, Liszt, Rizs",
    "image": "/images/sugar.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Cukor",
        "image": "/images2/koronas_cukor.webp",
        "link": "/cukrok_edesitok_liszt_rizs/cukor"
      },
      {
        "name": "Liszt",
        "image": "/images/hajdufinomliszt.png",
        "link": "/cukrok_edesitok_liszt_rizs/liszt"
      },
      {
        "name": "Só",
        "image": "/images/asztalijodozottso1kg.png",
        "link": "/cukrok_edesitok_liszt_rizs/so"
      },
      {
        "name": "Rizs",
        "image": "/images/orezrizs.png",
        "link": "/cukrok_edesitok_liszt_rizs/rizs"
      },
      {
        "name": "Édesítőszer",
        "image": "/images/sussina_edesito.webp",
        "link": "/cukrok_edesitok_liszt_rizs/edesito"
      },
      {
        "name": "Panírmorzsa",
        "image": "/images/panirmorzsa05kg.png",
        "link": "/cukrok_edesitok_liszt_rizs/panir"
      },
    ],
    "link": "/cukrok_edesitok_liszt_rizs",
    "svg": <TbBrandSugarizer style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Pékáru",
    "image": "/images/slicebread.png",
    "new" : true,
    "info": false,
    "categoryalt": [
      {
        "name": "Friss Kenyér",
        "image": "/images/szeleteltkenyer.png",
        "link": "/pekaru/frisskenyer"
      },
      {
        "name": "Croissant",
        "image": "/images2/7days_csokis.webp",
        "link": "/pekaru/croissant"
      },
      {
        "name": "Kenyér",
        "image": "/images/robertopanefettebarna.webp",
        "link": "/pekaru/kenyer"
      },
      {
        "name": "Gofri, Molnárka",
        "image": "/images/jagdfeldgofri125g1.png",
        "link": "/pekaru/gofri"
      },
      {
        "name": "Rizskorong",
        "image": "/images/real-puffasztott-rizskorong-natur.png",
        "link": "/pekaru/rizskorong"
      },
      {
        "name": "Hamburger zsemle",
        "image": "/images/jausszezammaghamburger.webp",
        "link": "/pekaru/hamburger"
      }
    ],
    "link": "/pekaru",
    "svg": <GiSlicedBread style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Olajok, Zsírok, Ecetek",
    "image": "/images/oil.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Olaj",
        "image": "/images2/venusz_etolaj1.webp",
        "link": "/olajok_zsirok_ecetek/olaj"
      },
      {
        "name": "Zsír",
        "image": "/images/palcso250g.png",
        "link": "/olajok_zsirok_ecetek/zsir"
      },
      {
        "name": "Ecet",
        "image": "/images2/burg_ecet10.webp",
        "link": "/olajok_zsirok_ecetek/ecet"
      },
    ],
    "link": "/olajok_zsirok_ecetek",
    "svg": <FaBottleDroplet style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Zöldség, Gyümölcs, Tojás",
    "image": "/images/paradicsom.png",
    "new": true,
    "info": false,
    "categoryalt": [
      {
        "name": "Zöldség",
        "image": "/images/repa.png",
        "link": "/zoldseg_gyumolcs/zoldseg"
      },
      {
        "name": "Gyümölcs",
        "image": "/images/banan.png",
        "link": "/zoldseg_gyumolcs/gyumolcs"
      },
      {
        "name": "Tojás",
        "image": "/images/arotojas.png",
        "link": "/zoldseg_gyumolcs/tojas"
      },
    ],
    "link": "/zoldseg_gyumolcs",
    "svg": <GiTomato style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Chipsek, Rágcsálnivalók",
    "image": "/images/chips.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Chips",
        "image": "/images2/chio_hagymastejfolos.webp",
        "link": "/chips/chips"
      },
      {
        "name": "Sós rágcsálnivaló",
        "image": "/images/mogy_foldimogyoro70g.webp",
        "link": "/chips/ragcsa"
      },
      {
        "name": "Popcorn",
        "image": "/images/privatpopcornsos.png",
        "link": "/chips/popcorn"
      },
      {
        "name": "Kétszersült",
        "image": "/images/7day_bakerollshagymas.webp",
        "link": "/chips/ketszersult"
      },
    ],
    "link": "/chips",
    "svg": <GiChipsBag style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Édesség",
    "image": "/images/sweets.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Cukorka",
        "image": "/images2/negro_classic79g.webp",
        "link": "/edesseg/cukor"
      },
      {
        "name": "Desszert",
        "image": "/images2/merci_piros.webp",
        "link": "/edesseg/desszert"
      },
      {
        "name": "Gumicukor",
        "image": "/images/haribo_bekas.webp",
        "link": "/edesseg/gumicukor"
      },
      {
        "name": "Nápolyi,Ostya",
        "image": "/images/andante_mogyoros.jpg",
        "link": "/edesseg/napolyi"
      },
      {
        "name": "Csoki",
        "image": "/images2/bounty.webp",
        "link": "/edesseg/csoki"
      },
      {
        "name": "Táblás Csoki",
        "image": "/images2/milka_alpesi100g.webp",
        "link": "/edesseg/tablas"
      },
      {
        "name": "Keksz",
        "image": "/images2/detki_ht200g.webp",
        "link": "/edesseg/keksz"
      },
      {
        "name": "Piskóta",
        "image": "/images/midifarcikao.webp",
        "link": "/edesseg/piskota"
      },
    ],
    "link": "/edesseg",
    "svg": <GiWrappedSweet style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Ásványvizek, Üdítők",
    "image": "/images/sodas.png",
    "info":true,
    "categoryalt": [
      {
        "name": "Ásványvíz",
        "image": "/images/szentkiralyi_mentes.png",
        "link": "/uditok/asvanyviz"
      },
      {
        "name": "Energiaital",
        "image": "/images/hell_classic.webp",
        "link": "/uditok/energiaital"
      },
      {
        "name": "Ízesített ásványviz",
        "image": "/images/mirrorcitrom.png",
        "link": "/uditok/izesitettasvanyviz"
      },
      {
        "name": "Alkoholmentes sör",
        "image": "/images/dreher24.png",
        "link": "/uditok/alkoholmentessor"
      },
      {
        "name": "Ice Tea",
        "image": "/images/xixo_gorogdinnye.webp",
        "link": "/uditok/icetea"
      },
      {
        "name": "Gyümölcslé",
        "image": "/images/heyho_szilva.webp",
        "link": "/uditok/gyumolcsle"
      },
      {
        "name": "Szénsavas üdítő",
        "image": "/images/pepsi_2l.webp",
        "link": "/uditok/szensavasudito"
      },
      {
        "name": "Szörp",
        "image": "/images/piroska_malna.webp",
        "link": "/uditok/szorp"
      },
    ],
    "link": "/uditok",
    "svg": <GiWaterBottle style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Sörök, Borok, Pezsgők",
    "image": "/images/beer.png",
    "info": true,
    "categoryalt": [
      {
        "name": "Sör",
        "image": "/images/kobanyai05l.webp",
        "link": "/sor_bor/sor"
      },
      {
        "name": "Bor",
        "image": "/images/lafiestaedeselmeny.png",
        "link": "/sor_bor/bor"
      },
      {
        "name": "Pezsgő",
        "image": "/images/torleycharmantjpg.png",
        "link": "/sor_bor/pezsgo"
      }
    ],
    "link": "/sor_bor",
    "svg": <PiBeerBottleFill style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Alkoholos Italok",
    "image": "/images/alcoholdrink.png",
    "info": true,
    "categoryalt": [
      {
        "name": "Cider",
        "image": "/images/somersby_apple.webp",
        "link": "/alkohol/cider"
      },
      {
        "name": "Gyógynövénylikör",
        "image": "/images/jager05l.png",
        "link": "/alkohol/gyogynovenylikor"
      },
      {
        "name": "Vodka",
        "image": "/images/royal05.png",
        "link": "/alkohol/vodka"
      },
      {
        "name": "Whisky",
        "image": "/images/jackdainels05l.png",
        "link": "/alkohol/whisky"
      },
      {
        "name": "Likör",
        "image": "/images/purestarapricot.png",
        "link": "/alkohol/likor"
      },
      {
        "name": "Konyak",
        "image": "/images/metaxa05l.png",
        "link": "/alkohol/konyak"
      },
      {
        "name": "Kommersz Szesz",
        "image": "/images/beregikorte005l.png",
        "link": "/alkohol/kommerszszesz"
      },
    ],
    "link": "/alkohol",
    "svg": <PiBeerBottleFill style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Fűszerek, Szószok, Alapporok",
    "image": "/images/spices.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Alappor",
        "image": "/images/knorr_bolognai89.webp",
        "link": "/fuszer-szosz-alappor/alappor"
      },
      {
        "name": "Fűszer",
        "image": "/images/l_majoranna.jpg",
        "link": "/fuszer-szosz-alappor/fuszer"
      },
      {
        "name": "Instant leves",
        "image": "/images/vishu-currys-csirkehus-izu-tesztaleves-60g.png",
        "link": "/fuszer-szosz-alappor/instantleves"
      },
      {
        "name": "Leveskocka",
        "image": "/images/maggi_gulyaskocka.webp",
        "link": "/fuszer-szosz-alappor/leveskocka"
      },
      {
        "name": "Szósz",
        "image": "/images/univer_ketchup470.webp",
        "link": "/fuszer-szosz-alappor/szosz"
      },
    ],
    "link": "/fuszer-szosz-alappor",
    "svg": <GiCoolSpices style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Tészták",
    "image": "/images/pasta2.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Levestészta",
        "image": "/images/laskodihazicsiga.png",
        "link": "/tesztak/levesteszta"
      },
      {
        "name": "Körettészta",
        "image": "/images/gyermelyi_szarvacska.webp",
        "link": "/tesztak/koretteszta"
      },
    ],
    "link": "/tesztak",
    "svg": <GiNoodles style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Konzervek, Befőttek",
    "image": "/images/jar.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Húskonzerv",
        "image": "/images/globus_kulonleges.webp",
        "link": "/konzervek-befottek/huskonzerv"
      },
      {
        "name": "Halkonzerv",
        "image": "/images/eva_szardinia.webp",
        "link": "/konzervek-befottek/halkonzerv"
      },
      {
        "name": "Készétel",
        "image": "/images/maestro-pietro-mexikoi-chilisbab-400-g.png",
        "link": "/konzervek-befottek/keszetel"
      },
      {
        "name": "Szendvicskrém, Pástétom",
        "image": "/images/globus_provence.webp",
        "link": "/konzervek-befottek/szendvicskrempastetom"
      },
      {
        "name": "Savanyúság",
        "image": "/images/arocsemegeuborka36.png",
        "link": "/konzervek-befottek/savanyusag"
      },
      {
        "name": "Szósz",
        "image": "/images/bens_szecsuani.webp",
        "link": "/konzervek-befottek/szosz"
      },
      {
        "name": "Zöldségkonzerv",
        "image": "/images/happi_chili.webp",
        "link": "/konzervek-befottek/zoldsegkonzerv"
      },
      {
        "name": "Befőtt, Gyümölcskonzerv",
        "image": "/images/mpananasz.png",
        "link": "/konzervek-befottek/gyumolcskonzerv"
      },
      {
        "name": "Lekvár",
        "image": "/images/regeecetesszilva.png",
        "link": "/konzervek-befottek/lekvar"
      },
    ],
    "link": "/konzervek-befottek",
    "svg": <GiCannedFish style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "WC papír, Kéztörlő, Papírzsebkendő, Betétek",
    "image": "/images/toilet.png",
    "info": false,
    "categoryalt": [
      {
        "name": "WC papír",
        "image": "/images/startrio_24tekercs.webp",
        "link": "/wcpapir_keztorlo_pzs_betet/wcpapir"
      },
      {
        "name": "Kéztörlő",
        "image": "/images/ooopspapirtorlo.webp",
        "link": "/wcpapir_keztorlo_pzs_betet/keztorlo"
      },
      {
        "name": "Papírzsebkendő",
        "image": "/images/sindy_aloe.webp",
        "link": "/wcpapir_keztorlo_pzs_betet/papirzsebkendo"
      },
      {
        "name": "Betét",
        "image": "/images/naturella_classic.webp",
        "link": "/wcpapir_keztorlo_pzs_betet/betet"
      },
      {
        "name": "Nedves törlőkendő",
        "image": "/images/papilion_lavender.webp",
        "link": "/wcpapir_keztorlo_pzs_betet/nedveskendo"
      },
    ],
    "link": "/wcpapir_keztorlo_pzs_betet",
    "svg": <FaToiletPaper style={{ width: '50px', height: '50px' }} />
  },

  {
    "name": "Háztartás",
    "image": "/images/clean.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Mosogatószer",
        "image": "/images/jar_lemon450ml.webp",
        "link": "/haztartas/mosogatoszer"
      },
      {
        "name": "Szappan",
        "image": "/images/baba-szappan-90g.png",
        "link": "/haztartas/szappan"
      },
      {
        "name": "Fertőtlenítő",
        "image": "/images/domestos_zold.webp",
        "link": "/haztartas/fertotlenito"
      },
      {
        "name": "Légfrissítő",
        "image": "/images/airwick_freshmaticpamut.webp",
        "link": "/haztartas/legfrissito"
      },
      {
        "name": "Szúnyog- és rovarírtószer",
        "image": "/images/chemotox400ml.png",
        "link": "/haztartas/szunyogesrovarirtoszer"
      },
      {
        "name": "Tisztítószer",
        "image": "/images/ciflemon.png",
        "link": "/haztartas/tisztitoszer"
      },
      {
        "name": "Alu és frissentartó fólia",
        "image": "/images/tuti_10m.png",
        "link": "/haztartas/aluesfrissentartofolia"
      },
      {
        "name": "Szemetes Zsák",
        "image": "/images/tuti25lzsak.png",
        "link": "/haztartas/szemeteszsak"
      },
      {
        "name": "WC tisztítószer",
        "image": "/images/bref_poweraktiv150g.webp",
        "link": "/haztartas/wctisztitoszer"
      },
    ],
    "link": "/haztartas",
    "svg": <BsHouseGearFill style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Baba termékek",
    "image": "/images/diaper.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Baba termékek",
        "image": "/images/diaper.png",
        "link": "/baba"
      },
    ],
    "link": "/baba",
    "svg": <FaBaby style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Szépségápolás, Egészség",
    "image": "/images/bodycare.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Női",
        "image": "/images/doveadvancedcareoriginal.png",
        "link": "/szepsegapolas/noi"
      },
      {
        "name": "Férfi",
        "image": "/images/oldspicescaptain150ml.png",
        "link": "/szepsegapolas/ferfi"
      },
      {
        "name": "Egészség",
        "image": "/images/masculanzold.webp",
        "link": "/szepsegapolas/egeszseg"
      },
    ],
    "link": "/szepsegapolas",
    "svg": <FaSprayCanSparkles style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Magvak, Műzlik",
    "image": "/images/seeds.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Magvak",
        "image": "/images/ancsannapraforgo.png",
        "link": "/magvak_muzlik_piskotak/magvak"
      },
      {
        "name": "Zabkása",
        "image": "/images/droetkervitaliscsokis.webp",
        "link": "/magvak_muzlik_piskotak/zabkasa"
      },
      {
        "name": "Gabonapehely",
        "image": "/images/soul_crunch.png",
        "link": "/magvak_muzlik_piskotak/gabonapehely"
      },
      {
        "name": "Hüvelyesek",
        "image": "/images/tunderkertsargaborso400g.webp",
        "link": "/magvak_muzlik_piskotak/huvely"
      },
    ],
    "link": "/magvak_muzlik_piskotak",
    "svg": <PiGrainsFill style={{ width: '50px', height: '50px' }} />
  },
  {
      "name": "Fagyasztott Élelmiszer",
      "image": "/images/fagyasztott_elelmiszer.png",
      "new": true,
      "info": false,
      "categoryalt": [
        {
          "name": "Pizza",
          "image": "/images/droetker4sajtos.webp",
          "link": "/fagyasztott_elelmiszer/pizza"
        },
        {
          "name": "Fagyasztott Krumpli",
          "image": "/images/arosultkrumpli1kg.png",
          "link": "/fagyasztott_elelmiszer/fagyasztottkrumpli"
        },
        {
          "name": "Készételek",
          "image": "/images/dixikentucky.png",
          "link": "/fagyasztott_elelmiszer/keszetel"
        },
        {
          "name": "Hal",
          "image": "/images/oceancatchfishfingers.png",
          "link": "/fagyasztott_elelmiszer/hal"
        }, 
        {
          "name": "Zöldségek, Gyümölcsök",
          "image": "/images/privatfranciasalata900g.png",
          "link": "/fagyasztott_elelmiszer/zoldseg_gyumolcs"
        },
        {
          "name": "Tészta, Desszert",
          "image": "/images/frigottileveles.png",
          "link": "/fagyasztott_elelmiszer/teszta_desszert"
        },
        {
          "name": "Jégkrémek",
          "image": "/images/koraltopgun.png",
          "link": "/fagyasztott_elelmiszer/jegkrem"
        },
        // {
        //   "name": "Hús",
        //   "image": "/images/",
        //   "link": "/fagyasztott_elelmiszer/jegkrem"
        // },
      ],
      "link":"/fagyasztott_elelmiszer",
      "svg": <GiFrozenOrb  style={{width:'50px', height:'50px'}}/>
  },  
  {
    "name": "Tejtermékek, Felvágottak",
    "image": "/images/milk.png",
    "new": true,
    "info": false,
    "categoryalt": [
      {
        "name": "Tej",
        "image": "/images/arotejuht.png",
        "link": "/tejtermekek_felvagottak/tej"
      },
      {
        "name": "Tejföl, Kefir",
        "image": "/images/szatmarfol.png",
        "link": "/tejtermekek_felvagottak/tejfolkefir"
      },
      {
        "name": "Joghurt",
        "image": "/images/jogobellaoszi.webp ",
        "link": "/tejtermekek_felvagottak/joghurt"
      },
      {
        "name": "Desszert",
        "image": "/images/kindermaxi.png",
        "link": "/tejtermekek_felvagottak/desszert"
      },
      {
        "name": "Margarin",
        "image": "/images/floraomega36.png",
        "link": "/tejtermekek_felvagottak/margarin"
      },
      {
        "name": "Vaj, sajtkrém",
        "image": "/images/medvesajtnatur.png",
        "link": "/tejtermekek_felvagottak/vaj_sajtkrem"
      },
      {
        "name": "Szalámi, Felvágott",
        "image": "/images/privatcsemegeszalami.png",
        "link": "/tejtermekek_felvagottak/szalami_felvagott"
      },
      {
        "name": "Májkrém",
        "image": "/images/gyulaimajas.png",
        "link": "/tejtermekek_felvagottak/majas"
      },
      {
        "name": "Virsli",
        "image": "/images/fustlivirsli.png",
        "link": "/tejtermekek_felvagottak/virsli"
      },
      {
        "name": "Tepertő",
        "image": "/images/palcsosertesteperto.png",
        "link": "/tejtermekek_felvagottak/teperto"
      },
      {
        "name": "Szalonna",
        "image": "/images/falusicsaszarszalonna.png",
        "link": "/tejtermekek_felvagottak/szalonna"
      },
    ],
    "link": "/tejtermekek_felvagottak",
    "svg": <GiMilkCarton style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Sütés, Főzés",
    "image": "/images/cake.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Süteménypor",
        "image": "/images/droetkercsokismuffin.webp",
        "link": "/sutes_fozes/sutemenypor"
      },
      {
        "name": "Főző-és habtejszín",
        "image": "/images/hulala200ml.webp",
        "link": "/sutes_fozes/tejszin"
      },
      {
        "name": "Sütési alapanyag",
        "image": "/images/droetkerszalalkali.webp",
        "link": "/sutes_fozes/sutesialapanyag"
      },
      {
        "name": "Sütemény dekoráció",
        "image": "/images/droetkertortazsele.webp",
        "link": "/sutes_fozes/sutemenydekoracio"
      },
      {
        "name": "Krém",
        "image": "/images/nutella200g.webp",
        "link": "/sutes_fozes/krem"
      },
    ],
    "link": "/sutes_fozes",
    "svg": <RiCake3Line style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Öblítők, Mosóporok",
    "image": "/images/oblito.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Öblítő",
        "image": "/images/coccolino_blue_1050ml.webp",
        "link": "/oblitok_mosoporok/oblito"
      },
      {
        "name": "Mosószer",
        "image": "/images/arielaquapudder20.png",
        "link": "/oblitok_mosoporok/mososzer"
      },
      {
        "name": "Mosókapszula",
        "image": "/images/ariel13mosas.jpg",
        "link": "/oblitok_mosoporok/mosokapszula"
      },
      {
        "name": "Mosópor",
        "image": "/images/tomi_mosopor2kg.png",
        "link": "/oblitok_mosoporok/mosopor"
      },
    ],
    "link": "/oblitok_mosoporok",
    "svg": <GiWashingMachine style={{ width: '50px', height: '50px' }} />
  },
  //   {
  //     "name": "Tisztítószerek, Mosószerek",
  //     "image": "/images/clean.png",
  //     "link": "/tisztitoszerek_mososzerek",
  //     "svg": <MdCleanHands style={{ width: '50px', height: '50px' }} />
  //   },
  {
    "name": "Kutya-Macska eledel",
    "image": "/images/catdog.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Kutya eledel",
        "image": "/images/privatkutyamarha.png",
        "link": "/kutya_macska/kutyaeledel"
      },
      {
        "name": "Macska eledel",
        "image": "/images/prmacska1kgcsirke.png",
        "link": "/kutya_macska/macskaeledel"
      },
    ],
    "link": "/kutya_macska",
    "svg": <PiDogFill style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Tusfürdő, Haj- és Szájápolás, Kozmetikum",
    "image": "/images/shower.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Férfi tusfürdő",
        "image": "/images/oldspices250mlwhitewater.png",
        "link": "/tusfurdo_hajapolas_kozmetikum/ferfitusfurdo"
      },
      {
        "name": "Női tusfürdő",
        "image": "/images/baba_tunderkert.webp",
        "link": "/tusfurdo_hajapolas_kozmetikum/noitusfurdo"
      },
      {
        "name": "Hajápolás",
        "image": "/images/headintense285ml.webp",
        "link": "/tusfurdo_hajapolas_kozmetikum/hajapolas"
      },
      {
        "name": "Szájápolás",
        "image": "/images/amodentherbal100ml.png",
        "link": "/tusfurdo_hajapolas_kozmetikum/szajapolas"
      },
      {
        "name": "Testápoló",
        "image": "/images/babatestapolokamilla.png",
        "link": "/tusfurdo_hajapolas_kozmetikum/testapolo"
      },
    ],
    "link": "/tusfurdo_hajapolas_kozmetikum",
    "svg": <FaBath style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Játékok",
    "image": "/images/uno3.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Játékok",
        "image": "/images/uno3.png",
        "link": "/jatekok"
      },
    ],
    "link": "/jatekok",
    "svg": <TbCards style={{ width: '50px', height: '50px' }} />
  },
  {
    "name": "Egyéb kellékek",
    "image": "/images/gear.png",
    "info": false,
    "categoryalt": [
      {
        "name": "Tasak",
        "image": "/images/tuti2l.png",
        "link": "/egyeb_kellekek/tasak"
      },
      {
        "name": "Elem",
        "image": "/images/duracellaa.png",
        "link": "/egyeb_kellekek/elem"
      },
      {
        "name": "Rágó",
        "image": "/images/orbitpepermint.png",
        "link": "/egyeb_kellekek/rago"
      },
      {
        "name": "Doboz",
        "image": "/images/tarolodoboz.png",
        "link": "/egyeb_kellekek/doboz"
      },
      {
        "name": "Tányér, Pohár",
        "image": "/images/muanyagpoharak03l.png",
        "link": "/egyeb_kellekek/tanyerpohar"
      },
    ],
    "link": "/egyeb_kellekek",
    "svg": <FaGears style={{ width: '50px', height: '50px' }} />
  },
]