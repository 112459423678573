export const favoriteBrand = [
    {
        "name":'Aro ',
        "image":'/images/arologos.png',
        "link":'/kedvencmarkak/aro'
    },
    {
        "name":'Gyermelyi ',
        "image":'/images/gyermelyi.png',
        "link":'/kedvencmarkak/gyermelyi'
    },
    {
        "name":'Kőbányai ',
        "image":'/images/kobanyai.png',
        "link":'/kedvencmarkak/kobanyai'
    },
    {
        "name":'Tchibo ',
        "image":'/images/tchibologo.png',
        "link":'/kedvencmarkak/tchibo'
    },
    {
        "name":'Hell',
        "image":'/images/helllogo.png',
        "link":'/kedvencmarkak/hell'
    },
    {
        "name":'Chio ',
        "image":'/images/chio-logo.png',
        "link":'/kedvencmarkak/chio'
    },
    {
        "name":'7DAYS ',
        "image":'/images/7dayslogo.png',
        "link":'/kedvencmarkak/7days'
    },
    {
        "name":'XIXO ',
        "image":'/images/xixologo.svg',
        "link":'/kedvencmarkak/xixo'
    },
    {
        "name":'Haribo ',
        "image":'/images/haribologo.png',
        "link":'/kedvencmarkak/haribo'
    },
    {
        "name":'Knorr ',
        "image":'/images/knorrlogo.svg',
        "link":'/kedvencmarkak/knorr'
    },
    {
        "name":'Maggi ',
        "image":'/images/maggilogo.png',
        "link":'/kedvencmarkak/maggi'
    },
    {
        "name":'Lucullus ',
        "image":'/images/luculluslogo.png',
        "link":'/kedvencmarkak/lucullus'
    },
    {
        "name":'Univer ',
        "image":'/images/univerlogo.png',
        "link":'/kedvencmarkak/univer'
    },
    {
        "name":'Győri ',
        "image":'/images/gyorilogo.png',
        "link":'/kedvencmarkak/gyori'
    },
    {
        "name":'Milka ',
        "image":'/images/milkalogo.png',
        "link":'/kedvencmarkak/milka'
    },
]