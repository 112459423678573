import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Radio,
	RadioGroup,
	Spacer,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setShipping, setPackingValue } from '../redux/actions/cartActions'; // Import setPacking
import { setAddress, setPayment } from '../redux/actions/orderActions';
import TextField from './TextField';
import TextArea from './TextArea';
import { Link as ReactLink } from 'react-router-dom';
import axios from 'axios';

const ShippingInformation = () => {
	const { shipping, packing } = useSelector((state) => state.cart); // Get packing from state
	const { shippingAddress } = useSelector((state) => state.order);

	const dispatch = useDispatch();

	const onSubmit = async (values) => {
		dispatch(setAddress(values));
		dispatch(setPayment()); // Send payment request with updated packing value
		sendOrderConfirmationEmail();
	};

	const sendOrderConfirmationEmail = async () => {
		try {
			await axios.post('https://eviabc.hu/api/sendorderconfirmationemail', {
				// Include necessary data for the email
			});
			console.log('Email sent successfully');
		} catch (error) {
			console.error('Error sending email:', error);
		}
	};

	return (
		<Formik
			initialValues={{
				address: shippingAddress ? shippingAddress.address : '',
				number: shippingAddress ? shippingAddress.number : '',
				comment: shippingAddress ? shippingAddress.comment : '',
			}}
			validationSchema={Yup.object({
				address: Yup.string().required('Szükségünk van a címedre.').min(2, 'Ez a cím túl rövid.'),
				number: Yup.string().required('Szükségünk van a telefonszámodra.').min(2, 'Ez a telefonszám túl rövid.'),
			})}
			onSubmit={onSubmit}>
			{(formik) => (
				<>
					<VStack as='form'>
						<FormControl>
							<TextField name='address' placeholder='Utca, házszám' label='Utca, házszám' />
							<TextField name='number' placeholder='Telefonszám' label='Telefonszám' />
							<TextArea name='comment' placeholder='Megjegyzés szállítási idővel kapcsolatban stb.' label='Megjegyzés' />
						</FormControl>
						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' mb='10'>
								Fizetési lehetőségek
							</Heading>
							<RadioGroup
								onChange={(e) => {
									dispatch(setShipping(e === 'express' ? '14.99' : '4.99'));
								}}
								defaultValue={shipping === 14.99 ? 'express' : 'withoutExpress'}>
								<Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
									<Stack pr='10' spacing={{ base: '8', md: '10' }} flex='1.5'>
										<Box>
											<Radio value='express'>
												<Text fontWeight='bold'>Fizetés a helyszínen</Text>
												<Text>Bankkártyával</Text>
											</Radio>
										</Box>
										<Stack spacing='6'>Express</Stack>
									</Stack>
									<Radio value='withoutExpress'>
										<Box>
											<Text fontWeight='bold'>Fizetés a helyszínen</Text>
											<Text>Készpénzzel</Text>
										</Box>
									</Radio>
								</Stack>
							</RadioGroup>
						</Box>
						<Box w='100%' pr='5'>
							<Heading fontSize='2xl' fontWeight='extrabold' mb='10'>
								Csomagolás
							</Heading>
							<RadioGroup
								onChange={(value) => {
									dispatch(setPackingValue(value === 'packing2')); // true for packing with cost, false otherwise
								}}
								value={packing ? 'packing2' : 'packing'} // Setting default value based on state
							>
								<Stack mb='5' direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
									<Stack pr='10' spacing={{ base: '8', md: '10' }} flex='1.5'>
										<Box>
											<Radio value='packing'>
												<Text fontWeight='bold'>Nem Szeretném kérni</Text>
											</Radio>
										</Box>
									</Stack>
									<Radio value='packing2'>
										<Box>
											<Text fontWeight='bold'>Szeretném kérni(+150Ft)</Text>
										</Box>
									</Radio>
								</Stack>
							</RadioGroup>
						</Box>
					</VStack>
					<Flex alignItems='center' gap='2' direction={{ base: 'column', lg: 'row' }}>
						<Button variant='outline' colorScheme='green' w='100%' as={ReactLink} to='/kosar'>
							Vissza a kosárhoz
						</Button>
						<Button
							variant='outline'
							colorScheme='green'
							w='100%'
							onClick={formik.handleSubmit}>
							Megrendelés
						</Button>
					</Flex>
				</>
			)}
		</Formik>
	);
};

export default ShippingInformation;
