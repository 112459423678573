import { Container, Box, Text,UnorderedList,ListItem } from "@chakra-ui/react";

const AdatkezelesiTajekoztato = () => {
    return (
        <Container maxW="container.lg">
            <Box p={{ base: "4", md: "8" }}>
                <Text fontSize='4xl' fontWeight='bold' textAlign="center" mb="8">
                    Adatvédelmi Nyilatkozat
                </Text>
                <Text fontSize='l' mb="4">
                    1. Az adatok kezelője az eviabc.hu weboldal (továbbiakban weboldal) üzemeltetője, Nagy László Györgyné (Székhely: 4080 Hajdúnánás Kéky Lajos utca 6 fszt/1.)
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    2. Az Évi ABC weboldalon elérhető egyes szolgáltatások regisztrációhoz kötöttek. A regisztráció során az adatkezelő bizonyos adatokat kérhet a felhasználótól. A regisztrációhoz való hozzáférés feltétele az ebben a dokumentumban meghatározott feltételek elfogadása. Amikor regisztrálsz a weboldalunkon annak érdekében, hogy hozzáférhess a regisztrációhoz kötött szolgáltatásainkhoz, egyidejűleg elfogadod ezen dokumentumban foglalt felhasználási feltételeket.A weboldal megtekintése során a technikai működés miatt automatikusan rögzítésre kerül a felhasználó látogatásának kezdő és befejező időpontja, valamint egyes esetekben - a felhasználó számítógépének beállításától függően - a böngésző, az operációs rendszer adatai és a felhasználó IP címe. Ezekből az adatokból a rendszer automatikusan statisztikai adatokat generál. Az adatokat a weboldal üzemeltetője kizárólag a honlap látogatottsági statisztikáinak elkészítésére használja, és nem kapcsolja össze más személyes adatokkal. A felhasználók a regisztrációval hozzájárulnak a weboldal üzemeltetőjének személyes adataik kezeléséhez. Az adatok kezelése a felhasználó önkéntes hozzájárulása alapján, a jelen tájékoztatás ismeretében történik.

                </Text>

                <Text fontSize='l' marginBottom='10px'>
                    3. A látogatás időpontjának, az IP címnek, valamint a böngésző és operációs rendszer adatainak rögzítése és tárolása technikailag szükséges a rendszer megfelelő működéséhez. Ezeket az információkat kizárólag statisztikai célokra használjuk, valamint az elektronikus levelek (megerősítés, visszaigazolás, postázási értesítő, ügyintézés) küldéséhez. A regisztráció során megadott személyes adatokat azért kezeljük, hogy lehetővé tegyük a weboldal szolgáltatásainak igénybevételét, valamint a szolgáltatások biztosítása érdekében a szolgáltató és a felhasználó közötti kommunikációt. Ezeket az adatokat összesítve és statisztikai célokra használjuk fel. A kapcsolattartásra használt adatokat biztonságosan tároljuk, és kizárólag a következő célokra használjuk fel:

                    Megrendeléseid kezelése"
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    4. Az adatkezelő a fent megjelölt céloktól eltérő célra a személyes adatokat nem kezeli. Az így megadott adatok kezelése a felhasználó önkéntes hozzájárulásával történik.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    5. A látogatás idejére vonatkozó adatokat, mint például az IP cím, operációs rendszer és böngésző adatok, a rendszer automatikusan törli a böngésző bezárásakor. A személyes adataidat addig tároljuk, amíg Te kifejezetten nem kéred azok törlését. Amennyiben szeretnéd, hogy adataidat töröljük, kérésedet írásban jelezheted az adatkezelő postacímén vagy a t0csa91@gmail.com e-mail címen. Az adatkezelő minden kérését legkésőbb két munkanapon belül teljesíti.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    6. Az adatkezelő soha nem küld ki kéretlen e-maileket (SPAM-et) semmilyen indokkal vagy módon. Csak azokra az e-mail címekre küldünk leveleket, amelyek tulajdonosai regisztráltak a weboldalunkon és elfogadták a Felhasználási feltételeket. Ha úgy gondolod, hogy kéretlen levelet kaptál tőlünk, vagy valakitől, aki az eviabc.hu képviselőjének adja ki magát, kérjük, értesíts minket a t0csa91@gmail.com e-mail címen, és mi haladéktalanul kivizsgáljuk az ügyet. A vizsgálat megkönnyítése érdekében kérjük, adj meg minden részletet, amit csak tudsz, és továbbítsd nekünk az általad kapott e-mailt teljes egészében.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    7. Az eviabc.hu semmilyen formában nem adja el, nem bérbe és nem teszi más cégek vagy magánszemélyek rendelkezésére a felhasználókra vonatkozó információkat, és nem vállalja azok kiküldését külső cégek vagy személyek részére vagy nevében. A felhasználók által megadott személyes adatokhoz, valamint a technikai működés miatt automatikusan rögzített adatokhoz kizárólag az adatkezelő munkatársai férnek hozzá. Harmadik személyeknek az adatkezelő nem adja át a személyes adatokat. Ez azonban nem vonatkozik az esetleges, törvény által előírt kötelező adattovábbításokra. Az adatkezelő minden egyes adatot gondosan megvizsgál, mielőtt eleget tenne az egyes hatósági adatkéréseknek, és szükség esetén kéri az adatvédelmi biztos véleményét. A felhasználó e-mail címe nem nyilvános és harmadik személyek számára nem hozzáférhető.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    8. A felhasználók által megadott személyes adatok kezelésének jogalapja a felhasználók önkéntes hozzájárulása. Minden látogató és regisztrált felhasználó tájékoztatást kérhet az általuk kezelt személyes adataikról. Az adatkezelő 30 napon belül írásban tájékoztatást nyújt az érintettnek az általa kezelt adatokról, beleértve az adatkezelés célját, jogalapját, időtartamát, az adatfeldolgozó nevét, címét (székhelyét) és tevékenységét, valamint az adatok címzettjeit és azok felhasználási célját. A tájékoztatást a felhasználó levelezési címének megadásával kérheti az adatkezelő levelezési címén vagy a t0csa91@gmail.com e-mail címen. A felhasználók jogosultak tiltakozni személyes adataik kezelése ellen, kérhetik adataik helyesbítését és törlését az adatkezelő levelezési címén vagy a t0csa91@gmail.com e-mail címen keresztül. A felhasználó bármikor leiratkozhat az adatkezelő levelezési listájáról, ami esetben nem kap több információt vagy ajánlatot az adatkezelőtől. Fontos azonban megjegyezni, hogy az ingyenes szolgáltatások esetében a leiratkozás egyben az ingyenes szolgáltatás további használatának jogáról való lemondást is jelentheti.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    9. Amennyiben lényeges változás következik be a szolgáltatás vagy az adatkezelés módjában, az adatkezelő értesítést küld a felhasználónak a felhasználó által megadott e-mail címre. Ha a felhasználó nem fogadja el a változásokat, ezt az értesítéstől számított 8 napon belül jelezheti a t0csa91@gmail.com ügyfélszolgálati e-mail címen. Ebben az esetben a felhasználó adatait töröljük a rendszerből, és ezzel a két fél közötti kapcsolat és bármilyen követelés megszűnik. Amennyiben a felhasználó az értesítéstől számított 8 napon belül nem jelzi, hogy nem fogadja el a változásokat, akkor azokat elfogadottnak tekintjük.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    10. Az eviabc.hu-ra bevitt adatokat saját szerverünkön, szigorú biztonsági protokollok mellett tároljuk. Minden nap rendszeres biztonsági másolatot készítünk az adatokról, melyet további biztonságos szervereken tárolunk. Ez azért fontos, hogy esetleges technikai vagy emberi hibák esetén is megőrizzük az adatok integritását. A regisztrációval felhasználóink elfogadják, hogy adataikat az ebben a dokumentumban leírtaknak megfelelően kezeljük és tároljuk.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    11. Kérjük a felhasználót, hogy amennyiben úgy érzi, hogy az eviabc.hu üzemeltetője megsértette személyes adatok védelméhez fűződő jogait, vegye fel velünk a kapcsolatot, hogy az esetleges jogsértéseket orvosolhassuk. Fontos tudni, hogy a felhasználók igényeiket polgári bíróság előtt is érvényesíthetik, illetve kérhetik az adatvédelmi biztos segítségét is. A személyes adatok védelmére vonatkozó részletes törvényi rendelkezéseket a személyes adatok védelméről és a közérdekű adatok nyilvánosságáról szóló 1992. évi LXIII. Törvény, valamint az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. Törvény tartalmazza. Amennyiben további kérdéseid lennének a személyes adatok védelmével kapcsolatban, kérlek, írj az alábbi e-mail címre: t0csa91@gmail.com. Az adattörlési kérelemre az adatkezelő legkésőbb 72 órán belül válaszol. Fontos megjegyezni, hogy a frankodiszkont.hu Magyarország területén érvényes jogszabályok szerint működik, és vitás kérdések esetén ezek a jogszabályok irányadóak.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    12. Az adatkezelő fenntartja magának a jogot, hogy az adatvédelmi irányelveit módosítsa. Ez különösen akkor lehet szükséges, ha a szolgáltatások körét kibővítik, vagy ha jogszabályok változnak. Fontos hangsúlyozni, hogy az adatkezelés módosítása nem eredményezheti a személyes adatok céljától való eltérő kezelését. Az ilyen változtatásokról szóló tájékoztatást az adatkezelő legalább 15 nappal az előre a honlapján közzéteszi.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    13. Az eviabc.hu nem vállal felelősséget az oldal használatából eredő közvetett vagy közvetlen károkért. Ez magában foglalja a rendelések teljesítéséből vagy késéséből, a szolgáltatások leállásából származó, valamint az esetleges üzleti vagy személyes jellegű információk elvesztéséből vagy egyéb anyagi veszteségből eredő károkat. A felhasználó teljes felelősséggel tartozik minden olyan rendelésért és tranzakcióért, amely során jelszava használatra kerül. Ezért kérjük minden felhasználót, hogy gondoskodjon jelszava biztonságos titokban tartásáról. A felelősség nem terheli a felhasználót, ha jelszava illetéktelen személy(ek) kezébe kerül saját hibáján kívül. Az eviabc.hu munkatársai mindent megtesznek azért, hogy az oldalon megjelenő adatok (termékek ára, elérhetősége, leírása stb.) a lehető legpontosabbak legyenek. Az esetleges hibákért a Szolgáltató felelősségét a 17/1999. (II.5.) kormányrendelet és a Polgári Törvénykönyvben rögzített rendelkezések határozzák meg. Minden termék árát bruttó összegben, azaz ÁFA-val együtt tüntetjük fel. Fenntartjuk az árváltoztatás jogát. A termékek adatlapján látható képek eltérhetnek a valóságtól, bizonyos esetekben csak illusztrációként szolgálnak. A vállalatunk nem vállal felelősséget az esetleges technikai jellemzők előzetes bejelentés nélküli változásáért, amelyeket a beszállító vagy más, rajtunk kívül álló okok miatt hajtanak végre.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    14. Az eviabc.hu weboldalon található minden tartalom szerzői jogvédelem alatt áll. Ide tartozik a honlap domain neve, a frankodiszkont.hu logója, a honlapon alkalmazott szerkesztési elvek, eljárások, módszerek, az oldal megjelenése és arculata, valamint a frankódiszkont által használt mottók, szoftveres és más egyedi megoldások, ötletek és megvalósítások, továbbá minden írásos, képi, animációs vagy hanganyag, amelyet a frankódiszkont közzétett. A hozzászólásokat csak személyes célra lehet kinyomtatni vagy terjeszteni, és írásbeli engedéllyel lehet őket más módon felhasználni. A nyilvános kommunikációs csatornákon megjelenő hozzászólások és közlemények esetében az érvényes jogszabályok irányadóak.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
                    15. 
Az eviabc.hu-n keresztül a termékek vagy szolgáltatások lejáratása vagy megbecsmérlése a jó hírnév megsértésének minősül, mivel a személyhez fűződő jogok védelme a jó hírnév védelmét is magában foglalja, ahogy azt az Ptk. 78. § (1) bekezdése rögzíti. A jó hírnév sérelmét különösen akkor jelentik, ha valaki olyan téves vagy sértő állítást tesz egy másik személyre vonatkozóan, amely valótlan, vagy hamis fényt vet rá. Az üzemeltető jogosult az ilyen helytelen vagy sértő viselkedésre reagálva a felhasználó hozzászólásait indoklás nélkül törölni.
                </Text>
                <Text fontSize='l' marginBottom='10px'>
        16. 
      </Text>
      <UnorderedList ps='15px'>
        <ListItem>
          1992. évi LXIII. törvény - a személyes adatok védelméről és a közérdekű adatok nyilvánosságáról
        </ListItem>
        <ListItem>
          1998. évi VI. törvény az egyének védelméről a személyes adatok gépi feldolgozásáról
        </ListItem>
        <ListItem>
          17/1999 (II. 5) kormányrendelet  a távollevők között kötött szerződésekről
        </ListItem>
        <ListItem>
          2001. évi CVIII. törvény - az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről
        </ListItem>
        <ListItem>
          226/2003. (XII. 13.) kormányrendelet az elektronikus hírközlési szolgáltató adatkezelésének különös feltételeiről, az elektronikus hírközlési szolgáltatások adatbiztonságáról, valamint az azonosító kijelzés és hívásátirányítás szabályairól
        </ListItem>
        <ListItem>
          2003. évi C. törvény az elektronikus hírközlésről
        </ListItem>
        <ListItem>
          2008. évi XLVIII. törvény a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól
        </ListItem>
        <ListItem>
          2011. CXII. törvény az információs önrendelkezési jogról és az információszabadságról
        </ListItem>
      </UnorderedList>
            </Box>
        </Container>
    );
}

export default AdatkezelesiTajekoztato;
