import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchProducts } from '../redux/actions/searchActions';
import { WrapItem, Center, Box, Wrap,Text } from '@chakra-ui/react';
import ProductCard from '../components/ProductCard';

const SearchScreen = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [autoComplete,setAutoComplete]=useState([])
    const [searchValue, setSearchValue] = useState(localStorage.getItem('searchValue') || "");
    const dispatch = useDispatch();
    const { loading, error, searchResults } = useSelector((state) => state.search); 


  const handleSearch = (searchValue) => {
        dispatch(searchProducts(searchValue));
        // Átadott érték beállítása
        setSearchTerm(searchValue);
    };

    useEffect(() => {
        const storedSearchValue = localStorage.getItem('searchValue');
        if (storedSearchValue !== searchValue) {
            setSearchValue(storedSearchValue || "");
        }
    }, []);

    return (
        <div>
             <Box style={{textAlign:'center'}} display='flex' justifyContent='center' marginY='20px'>
            <Text fontSize='4xl' fontWeight='bold'><Text> Keresési eredmények: '{searchValue}'</Text></Text>



            </Box>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {searchResults && (
             <Box>
             <Wrap spacing='30px' justify='center' minHeight='80vh' mx={{ base: '12', md: '20', lg: '32' }}>
                    {searchResults.map((product) => (
                        <WrapItem key={product._id}>
                        <Center w='250px' h='450px'>
                            <ProductCard product={product} loading={loading} />
                        </Center>
                    </WrapItem>
                    ))}
                </Wrap>
                </Box>
            )}
        </div>
    );
};

export default SearchScreen;
