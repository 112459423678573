import { Button, ButtonGroup, Container, Divider, IconButton, Input, Stack, Text, useColorModeValue as mode, Box, Flex, Icon } from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { BsPhoneFlip } from 'react-icons/bs';
import { TbShoppingCart } from 'react-icons/tb';
import { Link as ReactLink } from 'react-router-dom';
import ScrollToTopButton from './ScrollToButton';
import ColorModeToggle from './ColorModeToggle';

const Footer = () => {

    
	const handleClickToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	  };


    return (
        <Box w='100%' color='white' bg={mode('gray.700', 'gray.900')}>
            <Container as='footer' maxW='7xl'>
                <Stack spacing='12' direction={{ base: 'column', md: 'row' }} align={{ base: 'center', md: 'center' }} justify={{ base: 'space-evenly', md: 'space-evenly' }} py={{ base: '12', md: '16' }}>
                    <Stack spacing={{ base: '6', md: '8' }} align='start'>
                       
                            <Flex onClick={handleClickToTop} alignItems='center' as={ReactLink} to='/'>
                                <Icon as={TbShoppingCart} h='10' color={mode('white', 'yellow.200')} />
                                <Text fontSize='2xl' fontWeight='extrabold'>Évi ABC</Text>
                            </Flex>
                        
                        <Text fontWeight='bold' color='muted'><a href="tel:+36702074102">+36 70 207 4102</a></Text>
                    </Stack>
                    <Stack direction={{ base: 'column-reverse', md: 'column', lg: 'row' }} alignItems={{ base: 'center', md: 'center' }} spacing={{ base: '12', md: '8' }}>
                        <Stack spacing='12' flex='1'>
                            <Text fontSize='md' fontWeight='bold' textAlign='center' color='subtle'>Elérhetőségek</Text>
                            <Stack spacing='3' shouldWrapChildren direction='column' alignItems={{base:'center',md:'start'}}>
                                <Text variant='link'>Cím: 4080 Hajdúnánás, Sarló utca 45.</Text>
                                <Text fontWeight='bold' color='muted'>
                                    <a href="tel:+36702074102">+36 70 207 4102</a>
                                </Text>

                                <Text variant='link'>Email: <a href="mailto:t0csa91@gmail.com">t0csa91@gmail.com</a>  </Text>
                            </Stack>
                        </Stack>
                        <Stack spacing='12' flex='1'>
                            <Text fontSize='md' fontWeight='bold' textAlign='center' color='subtle'>Információk</Text>
                            <Stack spacing='6' shouldWrapChildren direction='column'>
                                
                                    <Button onClick={handleClickToTop} as={ReactLink} to='/altalanos-szerzodesi-feltetelek' fontWeight='400' color={mode('white', 'white')} variant='link'>Általános szerződési feltételek</Button>
                               
                               
                                    <Button onClick={handleClickToTop} as={ReactLink} to='/szallatasi-es-fizetesi-informaciok' fontWeight='400' color={mode('white', 'white')} variant='link'>Szállítási és fizetési információk</Button>
                                
                                
                                    <Button onClick={handleClickToTop} as={ReactLink} to='/adatkezelesi-tajekoztato' fontWeight='400' color={mode('white', 'white')} variant='link'>Adatkezelési tájékoztató</Button>
                                


                            </Stack>
                        </Stack>
                    </Stack>

                    {/* <Stack spacing='4'>
                        <Text fontSize='sm' fontWeight='semibold' color='subtle'> Stay up to date</Text>
                        <Stack spacing='4' direction={{base:'column',sm:'row'}} maxW={{lg:'360px'}}>
                            <Input placeholder='Enter Your email' type='email' required/>
                            <Button variant='primary' type='submit' flexShrink='0'>Subscribe</Button>
                       
                        </Stack>
                    </Stack> */}
                </Stack>

                <Divider />
                <Stack pt={{ base: '0', md: '8' }} pb={{ base: '12', md: '8' }} mb={{ base: '5', md: '0' }} justify='space-between' direction={{ base: 'column-reverse', md: 'row' }} align='center'>
                    <Text fontSize='sm' color='subtle'>
                        &copy; {new Date().getFullYear()} Évi ABC. Minden jog fenntartva.
                    </Text>
                    <ButtonGroup variant='ghost'>

                        <IconButton as='a' href='https://www.facebook.com/eviabc2024/' icon={<FaFacebook color='gray'  fontSize='1.25rem' />} />
                       <ColorModeToggle color='gray' display={{ base: 'block', md: 'none' }} />
                    </ButtonGroup>
                </Stack>
            </Container>
        </Box >
    );
}

export default Footer;