import { useState } from "react";
import { categoryData } from "../categoryData";
import { Link as ReactLink } from "react-router-dom";
import { WrapItem, Box, Wrap, Text, Link } from "@chakra-ui/react";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";

const CategoryAlt = ({ categoryAlt }) => {
    const [visibleCategories, setVisibleCategories] = useState(20);
    const [showAll, setShowAll] = useState(false);

    const toggleCategories = () => {
        setShowAll(!showAll);
        setVisibleCategories(showAll ? 5 : categoryAlt.length);
    };

    return (
        <Box marginBottom='20px'>
      
            <Wrap spacing='20px' justify='center' align='center'>
                {categoryAlt.slice(0, visibleCategories).map((category) => (
                    <Box className="category-button" key={category.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <WrapItem key={category.name} display='flex' justifyContent='center' w={{ base: '40px', md: '75px' }} h={{ base: '40px', md: '75px' }}>
                            <ReactLink to={category.link} style={{ width: '100%', height: '100%', backgroundImage: `url(${category.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></ReactLink>
                        </WrapItem>
                        <Link
                            fontSize={{ base: '10px', md: '16px' }}
                            fontWeight='semibold'
                            as={ReactLink}
                            to={category.link}
                            display='flex'
                            justifyContent='center'
                            textDecoration="none"
                            _hover={{
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px red',
                            }}
                        >
                            {category.name}
                        </Link>
                    </Box>
                ))}
               
            </Wrap>
        </Box>
    );
}

export default CategoryAlt;
