import { categoryData } from "../categoryData";
import { Link as ReactLink } from "react-router-dom";
import { WrapItem, Badge, Box, Wrap, Image, Text, Link, Flex, IconButton, Container } from "@chakra-ui/react";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { favoriteBrand } from "../favoriteBrandData";

const handleClickToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

const FavoriteBrand = () => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
            <Box textAlign='center'>
                <Wrap my={3}>
                    <Text fontSize='3xl'fontWeight='bold' textAlign='center'>Kedvelt forgalmazott márkáink</Text>
                </Wrap>
            </Box>
            <Wrap mb={10} spacing={10} justify='space-between' flexWrap='wrap' width='100%' maxW='800px'>
                {favoriteBrand.map((brand) => (
                    <Box onClick={handleClickToTop} key={brand.name} display='flex' justifyContent='center' alignItems='center' flex='1'>
                        <ReactLink to={`${brand.link}`}>
                            <Image maxW='60px' src={brand.image} />
                        </ReactLink>
                    </Box>
                ))}
            </Wrap>
        </Box>
    );
}

export default FavoriteBrand;
