import {
	Box,
	Badge,
	Flex,
	Heading,
	HStack,
	Icon,
	Image,
	Link,
	Skeleton,
	Stack,
	Wrap, WrapItem,
	useColorModeValue as mode,
	Text,
	Container,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as ReactLink } from 'react-router-dom';
import { BsPhoneFlip } from 'react-icons/bs';
import { categoryData } from '../categoryData';
import { GiTakeMyMoney } from "react-icons/gi";
import { TbTruckDelivery } from 'react-icons/tb';
import { BsMouse } from "react-icons/bs";
import ScrollToTopButton from '../components/ScrollToButton';
import { useEffect, useRef } from 'react';
import Szabadsag from '../components/Szabadsag';
import ConfirmAgesAlert from '../components/ConfirmAgesAlert';
import CaptionCarousel from '../components/CarouselLandingScreen';
import { useState } from 'react';
import FavoriteBrand from '../components/FavoriteBrand';
const LandingScreen = () => {

	const [isOpen, setIsOpen] = useState(true);
	const [show, setShow] = useState(false)
	const boxRef = useRef(null);

	useEffect(() => {
		// Az alert 3 másodperc elteltével automatikusan bezáródik
		const timeout = setTimeout(() => {
			setIsOpen(false);
		}, 30000);

		// Tisztítjuk a timeout-ot a komponens megunmountolásakor
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const scrollToTop = () => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		};

		scrollToTop();
	}, []);

	const handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleClickToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (



		<Box maxW='8xl' mx='auto' p={{ base: '0', lg: '12' }} minH='6xl'>
			{/* <Flex justifyContent="center" alignItems="center" flexDirection={{ base: 'column', lg: 'row' }}>
				<Box

					mx={{ base: '6', md: '8', lg: '0' }}
					px={{ base: '6', md: '8', lg: '0' }}
					py={{ base: '3', md: '8', lg: '12' }}
					display='flex'
					flexDirection='column'
					alignItems='center'
					textAlign='center'
					mb={{ base: '0', lg: '0' }}
				>
					<Stack spacing={{ base: '8', lg: '10' }} direction={{ base: 'column', lg: 'row' }}>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>10000 Ft vásárlás felett 3% kedvezmény</Text>

						</Heading>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<TbTruckDelivery style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>Ingyenes házhozszállítás 1 órán belül Hajdúnánás területén hétköznap 12-18 óra között</Text>
						</Heading>
						<Heading style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} color='white' bg='green.500' rounded='lg' p='8' size='xl' fontWeight='normal'>
							<GiTakeMyMoney style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} />
							<Text fontSize={{ base: '20px', md: '36px' }}>15000 Ft vásárlás felett 6% kedvezmény</Text>

						</Heading>
					</Stack>
					<HStack display='flex' flexDirection='column' spacing='' mt='10'> */}

						{/* <Link as={ReactLink} to='' color={mode('red.300', 'yellow.200')}>
						Fedezze fel
					</Link> */}
						{/* <Icon color={mode('red.300', 'yellow.200')} as={FaArrowRight} /> */}
						
					{/* </HStack>
				</Box>
			</Flex> */}
			<Flex my={{base:'25px',md:'0px'}}>
			<CaptionCarousel />	
			</Flex>
			
			<Wrap justify='center' pt={{base:'0',md:'10'}} pb={{base:'0',md:'10'}} >
			<BsMouse fontSize='50px' color={mode('#fc8181', '#faf089')} />
			</Wrap>
			<Wrap justify='center' pt='' pb='10'>
				
				<Text fontWeight='semibold' fontSize='3xl' justify='center'>Fedezze fel termékeinket!</Text>
			</Wrap>
			<Wrap spacing={{ base: '20px', md: '80px' }} marginBottom='20px' justify='center' wrap="wrap">
				{categoryData.map((category) => (
					<Box
						position='relative'
						as={ReactLink}
						key={category.name}
						w={{ base: 'calc(45% - 10px)', md: '200px' }}
						h='150px'
						p='5'
						bg='green.500'
						rounded='lg'
						color='white'
						className="category-button"
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							textDecoration: 'none',
						}}
						_hover={{ transform: 'scale(1.02)', transitionDuration: '0.5s' }}
						to={category.link}
						ref={boxRef}
						onClick={handleClick}
					>
						<WrapItem key={category.name} display='flex' justifyContent='center' w='50px' h='50px'>
							<span>{category.svg}</span>
						</WrapItem>

						<Link
							fontSize='15'
							display='flex'
							justifyContent='center'
							style={{ textAlign: 'center' }}
							_hover={{ textDecoration: 'none' }}
						>
							{category.name}
						</Link>
						{category.new && (
							<>
								<Badge
									textAlign='center'
									borderTopLeftRadius='5px'
									position='absolute'
									left={{ base: '0px', md: '0px' }}
									top={{ base: '0px', md: '0px' }}
									w={{ base: '60px', md: '95px' }} fontSize={{ base: '0.5em', md: '0.8em' }} colorScheme='red'>
									ÚJDONSÁG
								</Badge>
								{/* <Image 
                            position='absolute'
                            left={{base:'-25px',md:'-5px'}}
                            top={{base:'-25px',md:'-5px'}}
                            src="/images/ujdonsag.png" alt="New" width="60px" height="60px" /> */}
							</>
						)}
					</Box>
				))}
			</Wrap>

			<Wrap>
				<FavoriteBrand/>
			</Wrap>

			{show && <Szabadsag isOpen={isOpen} onClose={() => setIsOpen(false)} />}

		</Box>


	)
};

export default LandingScreen;